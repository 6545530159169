import img1 from "../Assates/Model/kurta1.webp";
import img2 from "../Assates/Model/kurta 2.webp";
import img3 from "../Assates/Model/wedding1.webp";
import img4 from "../Assates/Model/catlog1.webp";
import men1 from "../Assates/Product/product1.webp";
import men2 from "../Assates/Model/wedding-male1.webp";
import men3 from "../Assates/Product/product3.webp";
import men4 from "../Assates/Product/product4.webp";
import kid1 from "../Assates/Model/chaild1.webp";
import kid2 from "../Assates/Model/chaild2.webp";
import kid3 from "../Assates/Product/kids3.webp";
import kid4 from "../Assates/Product/kids4.webp";
import women1 from "../Assates/Model/churidar1.webp";
import women2 from "../Assates/Model/churidar2.webp";
import women3 from "../Assates/Product/women1.webp";
import women4 from "../Assates/Model/churidar4.webp";
import saree1 from "../Assates/Model/shari3.webp";
import saree2 from "../Assates/Product/saree3.webp";
import saree3 from "../Assates/Product/saree4.webp";
import saree4 from "../Assates/Model/sharee1.webp";



export const mensClothingProducts = [
  {
    id: 13,
    name: "Men Solid Jacket",
    brand: "Jacket",
    price: 2699,
    color: "Black",
    size: "Medium",
    img: men1,
  },
  {
    id: 14,
    name: "Tradition of Rajasthani",
    brand: "Rajputi",
    price: 2599,
    color: "Blue",
    size: "32x32",
    img: men2,
  },
  {
    id: 15,
    name: "Checkered Spread Casual Shirt",
    brand: "Shirt",
    price: 34.99,
    color: "White",
    size: "Large",
    img: men3,
  },
  {
    id: 16,
    name: "Men Solid Bomber Jacket",
    brand: "Jacket",
    price: 39.99,
    color: "Khaki",
    size: "34x32",
    img: men4,
  },
];
export const kidsClothingProducts = [
  {
    id : 17,
    name: "Baby Girls Casual Top",
    brand: "Gap Kids",
    price: 12.99,
    color: "Red",
    size: "6",
    img: kid2,
  },
  {
    id : 18,
    name: "Knee Length Casual Dress",
    brand: "Old Navy",
    price: 17.99,
    color: "Blue",
    size: "4T",
    img: kid3,
  },
  {
    id : 19,
    name: "Full Sleeve Solid Hooded",
    brand: "Sweatshirt",
    price: 24.99,
    color: "Yellow/White",
    size: "8",
    img: kid4,
  },
  {
    id : 20,
    name: "Casual Kurta & Waistcoat Set",
    brand: "Carter's",
    price: 19.99,
    color: "Gray",
    size: "5",
    img: kid1,
  },
];
export const womensClothingProducts = [
  {
    name: "Chiffon Floral Print Dupatta",
    brand: "Dupatta",
    price: 699,
    color: "Pink",
    size: "M/L/XL",
    id : 1,
    img: women1,
  },
  {
    name: "Floral Print Organza Dupatta",
    brand: "Dupatta",
    price: 599,
    color: "Pink",
    size: "M/L/XL",
    id : 2,
    img: women2,
  },
  {
    name: "Chiffon Floral Print Dupatta",
    brand: "Dupatta",
    price: 499,
    color: "Orange",
    id : 3,
    size: "Medium",
    img: women4,
  },
  {
    name: "Fit and Flare Light Blue Dress",
    brand: "Hiral Creation",
    price: 399,
    color: "Gray",
    size: "S/M/L/XL/XXL",
    id : 4,
    img: women3,
  },
];
export const sareeProducts = [
  {
    name: "The tradition of Kerala",
    brand: "sarees",
    price: 799.99,
    color: "White",
    material: "Cotton or Silk",
    id : 5,
    img: saree1,
  },
  {
    name: "Woven Bollywood Cotton Silk",
    brand: "Silk",
    price: 699,
    color: "Green",
    id : 6,
    material: "Silk",
    img: saree2,
  },
  {
    name: "Embroidered Bollywood Net",
    brand: "Silk",
    price: 899.99,
    id : 7,
    color: "Pink",
    material: "Silk",
    img: saree3,
  },
  {
    name: "Printed Daily Wear Pure Cotton",
    brand: "Cotton",
    price: 499,
    color: "Yellow",
    id : 8,
    material: "Cotton",
    img: saree4,
  },
];
export const mixProduct = [
  {
    name: "Semi Stitched Lehenga",
    brand: "Dupatta",
    price: 2999,
    color: "Yellow/Orange",
    material: "Silk",
    id : 9,
    img: img1,
  },
  {
    name: "Semi-stitched Lehenga",
    brand: "Dupatta",
    price: 1999,
    color: "Green",
    id : 10,
    material: "Silk",
    img: img2,
  },
  {
    name: "Net Embellished Red ...",
    brand: "Dupatta",
    price: 299,
    id : 11,
    color: "Red",
    material: "Cotton / Silk",
    img: img3,
  },
  {
    name: "Indian Wear White Top",
    brand: "Top",
    price: 299.99,
    color: "White",
    id : 12,
    material: "Cotton / Silk",
    img: img4,
  },
]