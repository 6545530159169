import './Home.scss';
import HeroImg from '../../Assates/PageImage/Home.webp';
import { HomeItem } from '../../Datas/Home';
import HomeVideo from '../../Component/Video/HomeVideo';
import { useState } from 'react';
import { FaPlay } from "react-icons/fa";


function Home() {
    const [videoIs, setVideoIs] = useState(false);
    return (
        <div className="home-component" id='home'>
            <img src={HeroImg} alt="HeroImg" className='HeroImg' />
            {
                videoIs ? <HomeVideo setVideoIs={setVideoIs} /> : null
            }
            <div className="home-container">
                <div className="heading-box">
                    <h1 className="title">{HomeItem.title}</h1>
                    <div className="collection-box">
                        <h1 className="headline">{HomeItem.headline1}</h1>
                        <h1 className="headline">{HomeItem.headline2}</h1>
                    </div>
                    <p className="dascription">{HomeItem.dascription}</p>
                    <div className="btn-box">
                        <a href="#about" className='more-btn'>Explore More</a>
                        <div className="watch-btn" onClick={() => setVideoIs(true)}>
                            <FaPlay className="play-icon" />
                            <p className="btn-title">Watch Video</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home